import { IPicture } from '@koaly/koaly-ui';
import { makeSizes } from 'next-img';

export const responsiveToSource = (image: OptimizedImage): IPicture => {
  return {
    alt: '',
    aspectRatio: image.images[0].height / image.images[0].width,
    sources: [
      {
        type: image.type,
        srcSet: image.srcSet,
        sizes: makeSizes(image, undefined, image.breakpoints),
      },
      {
        type: 'image/webp',
        srcSet: image.srcSet,
        sizes: makeSizes(image, undefined, image.breakpoints),
      },
    ],
  };
};
